/* Button Hover effect */
.special-btn span {
    position: absolute;
    display: block;
}

.special-btn span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, transparent, #029fe8);
}

.special-btn:hover {
    border: none;
}

.special-btn:hover span:nth-child(1) {
    animation: topBorderAnimation 2.5s infinite;
}

.special-btn span:nth-child(2) {
    top: -100%;
    right: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(180deg, transparent, #029fe8);
}

.special-btn:hover span:nth-child(2) {
    animation: rightBorderAnimation 2.5s infinite;
    animation-delay: 1s;
}

.special-btn span:nth-child(3) {
    right: -100%;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(270deg, transparent, #029fe8);
}

.special-btn:hover span:nth-child(3) {
    animation: bottomBorderAnimation 2.5s infinite;
}

.special-btn span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #029fe8);
}

.special-btn:hover span:nth-child(4) {
    animation: leftBorderAnimation 2.5s infinite;
    animation-delay: 1s;
}

@keyframes topBorderAnimation {
    from { left: -100%; }
    to { left: 100%; } 
}

@keyframes rightBorderAnimation {
    from { top: -100%; }
    to { top: 100%; } 
}

@keyframes bottomBorderAnimation {
    from { right: -100%; }
    to { right: 100%; } 
}

@keyframes leftBorderAnimation {
    from { bottom: -100%; }
    to { bottom: 100%; } 
}

/* Project index effect */
.active-index {
    text-decoration: none;
    color: #fff !important;
    background-color: #029fe8; 
    padding-left: 20px !important;
    padding-right: 20px !important;
    transition: 0.3s ease-in-out;
}