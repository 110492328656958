body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  height: 100%;
}

body * {
  font-family: 'Ubuntu';	
}

.icon {
  padding-right: 10px;
}

.cursor {
  color: #6f0285;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Page CSS */
.containers, .container-fluid {
  background: #101111;
  height: 100%;
  display: block;
}

@media (orientation: landscape) {
  .containers {
    height: 937px;
  }
}

.active {
  border-right: 2px solid #029fe8;
  color: #029fe8 !important;
}

#skill-chart {
  transition: 0.3s ease-in-out;
}

#project-container {
  padding-right: 125px;
}

#contact-container {
  padding-right: 125px;
}

#footer-container {
  background-color: #0e0d0d;
}

#contact {
  padding-right: 100px;
}

.error-input {
  border-bottom: 3px solid #cc0a0a !important;
}

#modal-body {
  background-color: #101111;
  min-width: 500px;
  min-height: 82px;
}

#social-links-col {
  padding-right: 5px;
  padding-left: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 100px #292929 inset !important;
  transition: 0s;
}

@media screen and (max-width: 1055px) {
  #contact {
    padding-right: 20px;
  }
}

@media screen and (max-width: 576px) {
  #chart-container {
    display: none;
  }

  #project-container {
    padding-right: 20px;
  }

  #contact-container {
    padding-right: 20px;
  }

}

/* Icon Hovering */
#facebook-icon:hover {
  color: #1977f3;
  transition: 0.2s ease-in-out;
}

#instagram-icon:hover {
  color: #ab348e;
  transition: 0.2s ease-in-out;
}

#wechat-icon:hover {
  color: #00c924;
  transition: 0.2s ease-in-out;
}

#mail-icon:hover {
  color: #da4936;
  transition: 0.2s ease-in-out; 
}

#github-icon:hover {
  color: #80278f;
  transition: 0.2s ease-in-out;
}